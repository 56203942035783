.job-desc-comp .react-tagsinput {
    height: 40px;
    overflow: auto;
    border-radius: 4px;
    background-color: #F9FAFC;
}

.job-desc-comp .react-tagsinput--focused {
    border-color: #3182ce;
}

.react-tagsinput-input {
    width: 135px !important;
  }

.job-desc-comp .react-tagsinput-tag {
    background-color: #edf2f7;
    border-radius: 5px;
    border: 1px solid #edf2f7;
    color: #1f3b4b;
}
.job-desc-comp .MuiFormHelperText-root{
    margin-left:0px;
}
 .job-desc-comp .ql-container.ql-snow {
  overflow: auto;
  min-height: 210px;
  max-height: 307px;
}
.editdetailtab .MuiTabs-indicator {
    display:none;
}
.editdetailtab .MuiButtonBase-root.Mui-selected {
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 700;
    color: #353535;
}
.editdetailtab .MuiButtonBase-root{
    /* background-color: #EAEBF4; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-left: 2px;
    text-transform: none;
    font-size: 16px;
}
.jobapplication-list .MuiButtonBase-root{
    background-color: transparent!important;
}
.detailsection .MuiGrid-root.MuiGrid-item{
    height: 85px;
}
.detailsection .react-tagsinput {
    max-height: 40px;
    overflow: auto;
    border-radius: 4px;
}

.detailsection .react-tagsinput--focused {
    border-color: #3182ce;
}

.detailsection .react-tagsinput-tag {
    background-color: #edf2f7;
    border-radius: 5px;
    border: 1px solid #edf2f7;
    color: #1f3b4b;
}
.detailsection .MuiFormHelperText-root{
    margin-left:0px;
}
.errorCK .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #F44336!important;
}
.jobapplication-list .MuiDataGrid-virtualScroller{
    min-height: 300px;
}
.edit-job-detail .quill {
    max-height: 350px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.edit-job-detail .ql-container.ql-snow {
    overflow: auto;
    max-height: 300px;
    min-height: 300px;
  }
.share-popup .react-tagsinput {
  max-height: 40px;
  overflow: auto;
  border-radius: 4px;
  background-color: #f9fafc;
}

.share-popup .react-tagsinput--focused {
  border-color: #3182ce;
}

.share-popup .react-tagsinput-tag {
  background-color: #edf2f7;
  border-radius: 5px;
  border: 1px solid #edf2f7;
  color: #1f3b4b;
}
.share-popup .MuiFormHelperText-root {
  margin-left: 0px;
}

.toast-class {
  background-color: transparent;
  height: 30px;
  width: 480px;
  right: 10px;
  max-height: 30px;
}

.big-toast-class {
  background-color: transparent;
  height: 90px;
  width: 480px;
  right: 10px;
  max-height: 30px;
}

.Toastify__toast-theme--light {
  background-color: transparent !important;

}
.Toastify__close-button--light {
  opacity: 1!important;
}

.Toastify__toast{
  box-shadow: none;
}
/* .Toastify__close-button > svg {
  height: 19px;
  width: 19px;
  border: 1px solid #EAEAEA;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 3px;
  background: #EAEAEA;
  padding: 2px;
} */

.toast-class-success .Toastify__close-button > svg {
  height: 19px;
  width: 19px;
  border: 1px solid #40ad12!important;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  background: #F4FFEF;
  color:#40ad12!important;
  padding: 2px;
}

.toast-class-error .Toastify__close-button > svg {
  height: 19px;
  width: 19px;
  border: 1px solid #F81919!important;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  background: #FFE3E1;
  color:#F81919!important;
  padding: 2px;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* globle css */

.ck-powered-by {
  display: none;
}

/* css for hide table focus box */
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus-within,
.css-1wuhawx-MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}

.login-page
  .password-input-field
  .MuiInputBase-formControl.MuiInputBase-sizeSmall {
  background-color: #f8f8f8;
}

.ck-editor__main p {
  margin: 0px !important;
}

.ql-toolbar.ql-snow {
  background-color: #F4F6FF;
}

.ql-snow * {
  box-sizing: border-box;
  display: block;
}

.ql-editor {
  background-color: #FAFBFF;
}

.second-quill-container .ql-editor {
  background-color: #FFFFFF !important;
}


.enhancejdpopup .ql-container.ql-snow {
  border-bottom: 0px;
  overflow: auto;
  height: 307px;
}

.ql-toolbar button {
  color: #4A57A3 !important;
}

.ql-editor p,
.ql-container {
  font-size: 16px !important;
}

.ql-toolbar.ql-snow .ql-formats {
  color: #4A57A3 !important;
}

.cityinput {
  height: 41px;
  background: none;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
}

.citycontainer {
  height: 105px;
  position: relative;
}
.edit-job-detail .citycontainer .autoSearchList_city {
  margin-top: -20px;
}
.publish_job .citycontainer .autoSearchList_city {
  margin-top: -35px;
}
.citycontainer .autoSearchList_city {
  max-height: 175px;
  overflow: auto;
  background: #fff;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 9999;
  padding: 0px;
  margin-left: 1px;
  border-radius: 3px;
}

.autoSearchList_city li {
  width: 100%;
  float: left;
  padding: 0.6em 1em;
  transition: all 0.3s ease-in-out;
  list-style: none;
}

.autoSearchList_city li.active,
.autoSearchList_city li:hover {
  background-color: #eee;
}
.showexpandnav {
  max-width: 250px !important;
  position: absolute;
  z-index: 999;
  transition: "all 0.3s ease-in-out";
}

.navitem {
  cursor: pointer;
}
.navitem:hover .MuiBox-root {
  background-color: rgba(255, 255, 255, 0.067) !important;
}
.navitem:hover .MuiBox-root p,
.navitem:hover .MuiBox-root button svg {
  color: #fff !important;
}

.layloading {
  position: fixed;
  z-index: 9;
  height: 7em;
  width: 7em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: #6f717230; */
}

/* Transparent Overlay */
.layloading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #6f717230;
}

/* :not(:required) hides these rules from IE9 and below */
.layloading:not(:required) {
  /* hide "loading..." text */
  /* font: 0/0 a; SONAR */
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.singledetailtab .MuiButtonBase-root.Mui-selected {
  background: #fff;
  color: #4a57a3 !important;
  border-bottom: 3px solid #4a57a3;
}

.singledetailtab .MuiButtonBase-root {
  background: #fff !important;
}

.skillmatrix .MuiChip-outlined {
  padding: 0px 10px;
  margin: 5px;
  background-color: #f2f3fe;
  border: 2px solid #b1b9eb;
  font-size: 14px;
}

div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 2px solid #f5f5f5;
}

div::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

div::-webkit-scrollbar-track {
  background-color: #e0e0e0;
  border-radius: 10px;
}

div#msdoc-renderer iframe {
  height: 75vh !important;
}

.documents-list-header {
  background-color: #F8F8F8;
}
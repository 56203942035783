.editdetailtab .MuiTabs-indicator {
  display: none;
}
.editdetailtab .MuiButtonBase-root.Mui-selected {
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 700;
  color: #353535;
}
.editdetailtab .MuiButtonBase-root {
  /* background-color: #eaebf4; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-left: 2px;
  text-transform: none;
  font-size: 16px;
}
input.Mui-disabled {
  color: #000 !important;
  -webkit-text-fill-color: #000;
}
.Active {
  border: 1px solid #4fd465;
  background: #e3ffe8;
  padding: 0px 12px;
  border-radius: 50px;
}
.Active p {
  color: #0f621d;
  font-size: 12px;
}
.Inactive {
  border: 1px solid #d5130a;
  background: #ffe3e1;
  padding: 0px 12px;
  border-radius: 50px;
}
.Inactive p {
  color: #d5130a !important;
  font-size: 12px;
}
.Review {
  border: 1px solid #556efd;
  background: #daebff;
  padding: 0px 12px;
  border-radius: 50px;
}
.Review P {
  color: #104682;
  font-size: 12px;
}

br {
  display: contents;
}
